import { getUserFluxKey } from '@theway/user-flux-config';
import {
	initUserFlux,
	getAnonymousId,
} from '@theway/userflux-analytics/client';

import { setLinkTracker } from './tracker-link';

export const activateUFLinkTracker = () => {
	// inject UF link tracker to support cookie settings
	setLinkTracker({ uf: getAnonymousId() || 'annon' });
};

const userFlux = initUserFlux(getUserFluxKey(import.meta.env.VITE_ENVIRONMENT));

export default userFlux;
