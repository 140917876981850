import { createNanoEvents } from 'nanoevents';

export type ObservationEntry = {
	traceId: string;
	group: string;
	data: any;
	index: number;
};

const records: ObservationEntry[] = [];

const bus = createNanoEvents();

export const observe = (entry: Omit<ObservationEntry, 'index'>) => {
	records.push({ ...entry, index: records.length });
	bus.emit('addition', entry);
};

export const subscribeToHeimdall = (
	traceId: string,
	group: string,
	callback: (entry: ObservationEntry) => void,
) => {
	return bus.on('addition', (entry) => {
		if (entry.traceId === traceId && entry.group === group) {
			callback(entry);
		}
	});
};

export const collectHeimdallRecords = (traceId: string, group?: string) => {
	return records.filter(
		(x) => x.traceId === traceId && (!group || x.group === group),
	);
};
