import CodeOrgLogo from './assets/code-org-logo.png';
import CodeOrgXs from './assets/code-org-xs.jpg';
import CodeOrg from './assets/code-org.jpg';
import DVCollectiveRectLogo from './assets/dv-collective-logo-rect.png';
import DVCollectiveLogo from './assets/dv-collective-logo.png';
import DVCollectiveXs from './assets/dv-collective-xs.jpg';
import DVCollective from './assets/dv-collective.jpg';
import TheLifeYouCanSaveLogo from './assets/life-you-can-save-logo.png';
import TheLifeYouCanSaveXs from './assets/life-you-can-save-xs.jpg';
import TheLifeYouCanSave from './assets/life-you-can-save.jpg';
import MakeAWishRectLogo from './assets/make-a-wish-logo-rect.png';
import MakeAWishLogo from './assets/make-a-wish-logo.png';
import MakeAWishXs from './assets/make-a-wish-xs.jpg';
import MakeAWish from './assets/make-a-wish.jpg';
import RoomToReadLogo from './assets/room-to-read-logo.png';
import RoomToReadXs from './assets/room-to-read-xs.jpg';
import RoomToRead from './assets/room-to-read.jpg';
import ThreadTogetherRectLogo from './assets/thread-together-logo-rect.png';
import ThreadTogetherLogo from './assets/thread-together-logo.png';
import ThreadTogetherXs from './assets/thread-together-xs.jpg';
import ThreadTogether from './assets/thread-together.jpg';

export const CharityList = [
	{
		name: 'The Life You Can Save',
		squareLogo: TheLifeYouCanSaveLogo,
		rectLogo: '',
		imageXs: TheLifeYouCanSaveXs,
		image: TheLifeYouCanSave,
		purpose: 'Help end extreme poverty',
		exitText: 'We’re working together to end extreme poverty',
		logoBg: '#fff',
		logoStroke: '#EAECF0',
	},
	{
		name: 'Room to Read',
		squareLogo: RoomToReadLogo,
		rectLogo: '',
		imageXs: RoomToReadXs,
		image: RoomToRead,
		purpose: 'Support education & literacy',
		exitText: 'World change starts with educated children',
		logoBg: 'yellow',
	},
	{
		name: 'Code.org',
		squareLogo: CodeOrgLogo,
		rectLogo: '',
		imageXs: CodeOrgXs,
		image: CodeOrg,
		purpose: 'Expand computer science access',
		exitText: 'Every child deserves the chance to learn computer science',
		logoBg: '#fff',
		logoStroke: '#EAECF0',
	},
];

export const CharityOrgs = [
	{
		name: 'Thread Together',
		squareLogo: ThreadTogetherLogo,
		rectLogo: ThreadTogetherRectLogo,
		imageXs: ThreadTogetherXs,
		image: ThreadTogether,
		purpose: 'New clothing for those in need',
		exitText:
			'We’re working together to provide new wardrobes to those in need',
		logoBg: '#298594',
		logoStroke: '#298594',
	},
	{
		name: 'DV Collective',
		squareLogo: DVCollectiveLogo,
		rectLogo: DVCollectiveRectLogo,
		imageXs: DVCollectiveXs,
		image: DVCollective,
		purpose: 'Fight against domestic violence',
		exitText:
			'Supporting over 160 domestic violence front line services, including 40+ women’s shelters.',
		logoBg: '#FFFFFF',
		logoStroke: '#EAECF0',
	},
	{
		name: 'Make-A-Wish Australia',
		squareLogo: MakeAWishLogo,
		rectLogo: MakeAWishRectLogo,
		imageXs: MakeAWishXs,
		image: MakeAWish,
		purpose: 'Life changing wishes for critically ill kids',
		exitText:
			'Together we’re creating life-changing wishes for children with critical illnesses',
		logoBg: '#FFFFFF',
		logoStroke: '#EAECF0',
	},
];
