import { css } from '@linaria/core';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	position: relative;

	container-name: content;
	container-type: inline-size;

	display: grid;
	height: 100svh;
	max-height: 568px;
	width: calc(100% - 16px);
	max-width: 374px;
	margin-inline: auto;
	margin-bottom: 20px;

	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, sans-serif;
`;

export const wrapper = css`
	position: absolute;
	inset: 0;

	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 100%;
	width: 100%;
	height: 100%;

	border-radius: 16px;

	background-color: #ffffff;

	overflow: hidden;
`;

export const partnership = css`
	position: absolute;
	top: -34px;
	left: 50%;

	display: flex;
	justify-content: center;
	width: 100%;
	height: 64px;

	transform: translateX(-50%);
`;

export const content = css`
	position: absolute;
	inset-inline: 0;
	bottom: 0;

	display: grid;
	grid-template-rows: 1fr auto;

	min-height: 350px;
	width: 100%;
	padding-bottom: 24px;

	border-radius: 16px 16px 0 0;

	box-shadow: 0 -12px 24px -8px rgba(0, 0, 0, 0.12);
`;

export const contentWrapper = css`
	position: relative;
	height: 100%;
`;

export const close = css`
	position: absolute;
	top: 9px;
	right: 14px;
`;

export const decoration = css`
	position: relative;

	width: calc(100% - 48px);
	margin-inline: auto;
	aspect-ratio: 1.8;

	border-radius: 16px;

	background-color: #e5e5e5;

	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);

	${container('content', 'width < 358px')} {
		width: calc(100% - 40px);
	}
`;

export const decorationWrapper = css`
	position: absolute;
	inset: 0;

	border-radius: 16px;

	overflow: hidden;
`;

export const slide = css`
	position: absolute;
	inset: 0;

	& img {
		width: 100%;
		height: 100%;

		object-fit: cover;
		object-position: center;
	}
`;

export const hidden = css`
	display: none;
`;

export const badge = css`
	position: absolute;
	left: -9px;
	top: 12px;

	display: flex;
	align-items: center;

	width: auto;
	height: 20px;
	padding-block: 2px;
	padding-inline: 8px;

	border-radius: 0 9999px 9999px 0;

	background-color: #d7fd46;
`;

export const triangle = css`
	position: absolute;
	bottom: -4px;
	left: 0;
`;
