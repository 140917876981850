import type { FC, PropsWithChildren } from 'react';

import { Pagination } from '../pagination';
import type { PaginationControlProps } from '../pagination';

import { TextSm } from '../typography';

import * as styles from './styles';

export const Header: FC<
	PropsWithChildren & PaginationControlProps & { noPagination?: boolean }
> = ({ children, noPagination = false, ...props }) => {
	return (
		<div className={styles.header}>
			<div className={styles.title}>
				<TextSm as="p" weight="bold">
					{children}
				</TextSm>
			</div>
			{!noPagination && <Pagination {...props} />}
		</div>
	);
};
