import { uid } from 'uid';

type FixedLengthString<
	L extends number,
	S extends string = '',
> = S['length'] extends L ? S : S & { _brand: string };

export type ImpressionId = FixedLengthString<32>; //48

export const genUID = <Length extends number>(
	prefix: string,
	length: Length,
): FixedLengthString<Length> => {
	return `${prefix}${uid(length - prefix.length)}` as any;
};

/**
 * session id generation is aligned with the userflux session id generation
 */
export const getSessionId = () =>
	'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;

		return v.toString(16);
	});

export type ImpressionType = 'widget' | 'embed' | 'banner';

const IMPRESSION_TYPES: Record<ImpressionType, string> = {
	widget: 'w',
	embed: 'e',
	banner: 'b',
};

export const getImpressionId = (
	type: ImpressionType,
	region: 'US' | 'AU' | 'GB' | 'ZZ',
	version: 1 | 2 = 1,
): ImpressionId => {
	const ari = 'imp';
	const versionPrefix = version === 1 ? '0' : 'A';
	const typePrefix = IMPRESSION_TYPES[type] ?? '0';
	const regionPrefix = region;

	return genUID(`${ari}${typePrefix}${regionPrefix}${versionPrefix}`, 32);
};

export const getImpressionVersion = (impressionId: string): 1 | 2 => {
	// impwAU01234 -> 1
	// impwAUA1234 -> 2
	return impressionId[6] === 'A' ? 2 : 1;
};

export const replaceImpressionType = (
	impressionId: ImpressionId,
	type: ImpressionType,
): ImpressionId => {
	return (impressionId.slice(0, 3) +
		IMPRESSION_TYPES[type] +
		impressionId.slice(4)) as ImpressionId;
};

export const extractImpressionRegion = (impressionId: string): 'AU' | 'US' => {
	// impwAU1234 -> AU
	return impressionId.slice(4, 6) as any;
};

export const getPiiId = (
	type: 'provided' | 'collected',
	region: string,
): ImpressionId => {
	const ari = 'pii';
	const typePrefix = type === 'provided' ? 'p' : 'c';
	const regionPrefix = region;

	return genUID(`${ari}${typePrefix}${regionPrefix}`, 32);
};
