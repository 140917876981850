import { css } from '@linaria/core';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	display: flex;
	flex-direction: column;

	padding-inline: 24px;
	padding-top: 48px;
	padding-bottom: 24px;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}
`;

export const textWrapper = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 12px;
`;

export const text = css`
	text-align: center;
`;

export const subText = css`
	text-align: center;
`;

export const buttonsGroup = css`
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	row-gap: 8px;

	margin-top: 24px;
`;
