import { useAutofocusWithin } from '@smile/ds/a11y';
import type { FC, ReactNode } from 'react';

import { PrimaryButton, SecondaryButton } from '../actions';
import { H2, TextMd } from '../typography';

import * as styles from './styles';

type ContentProps = {
	text: ReactNode;
	subtext: ReactNode;
	actionText: string;
	onGetStarted(): void;
	onClose(): void;
};

export const SplashContent: FC<ContentProps> = ({
	text,
	subtext,
	onGetStarted,
	onClose,
	actionText: defaultActionText = 'View offers',
}) => {
	const buttonsRef = useAutofocusWithin<HTMLDivElement>(true);

	return (
		<div className={styles.component}>
			<div className={styles.textWrapper}>
				<H2 as="h2" className={styles.text}>
					{text}
				</H2>
				<TextMd as="p" className={styles.subText}>
					{subtext}
				</TextMd>
			</div>
			<div ref={buttonsRef} className={styles.buttonsGroup}>
				<PrimaryButton
					onClick={onGetStarted}
					actionName="get-started"
					autoFocus
				>
					{defaultActionText}
				</PrimaryButton>
				<SecondaryButton onClick={onClose} actionName="close-no-thanks">
					Maybe later
				</SecondaryButton>
			</div>
		</div>
	);
};

export const Thanks: FC<ContentProps> = (props) => {
	return <SplashContent {...props} />;
};
