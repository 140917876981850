import { VisuallyHidden } from '@smile/ds/a11y';
import { CloseIcon } from '@smile/ds/icons/Close';
import { PopupTeleporterTarget } from '@theway/shards';
import type { CSSProperties, FC, PropsWithChildren } from 'react';

import { IconButton } from '../actions';
import { AsteriskProvider } from '../control/state-controller';

import { ContextCloseTeleporter } from '../control/teleporters';

import { Header } from '../header';

import { Badge } from './Badge';
import { Content } from './Content';
import { Presentation } from './Presentation';
import * as styles from './styles';
import type { CommonProps, LayoutProps } from './types';

const logo = `
<svg viewBox="0 0 114 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.926415" y="0.815087" width="62.3698" height="62.3698" rx="31.1849" fill="#F3ACDE"/>
<rect x="0.926415" y="0.815087" width="62.3698" height="62.3698" rx="31.1849" stroke="white" stroke-width="1.63017"/>
<path d="M21.6831 10.4016C20.6592 10.4016 19.8291 11.2317 19.8291 12.2556V14.1095C19.8291 15.1335 20.6592 15.9635 21.6831 15.9635H22.6101L22.6101 21.5254C22.6101 22.5493 23.4401 23.3794 24.464 23.3794H26.318C27.3419 23.3794 28.172 22.5493 28.172 21.5254V15.9635H29.0989C30.1229 15.9635 30.9529 15.1335 30.9529 14.1095V12.2556C30.9529 11.2317 30.1229 10.4016 29.0989 10.4016H21.6831Z" fill="#09203E"/>
<path d="M32.8069 12.2556C32.8069 11.2317 33.6369 10.4016 34.6609 10.4016H36.5148C37.5387 10.4016 38.3688 11.2317 38.3688 12.2556V14.1095H38.8323V12.2556C38.8323 11.2317 39.6623 10.4016 40.6863 10.4016H42.5402C43.5641 10.4016 44.3942 11.2317 44.3942 12.2556V21.5254C44.3942 22.5493 43.5641 23.3794 42.5402 23.3794H40.6863C39.6623 23.3794 38.8323 22.5493 38.8323 21.5254V19.6715H38.3688V21.5254C38.3688 22.5493 37.5387 23.3794 36.5148 23.3794H34.6609C33.6369 23.3794 32.8069 22.5493 32.8069 21.5254V12.2556Z" fill="#09203E"/>
<path d="M22.841 26.9693C23.3189 25.8358 24.4601 25.1845 25.6226 25.2765C26.7852 25.1845 27.9264 25.8358 28.4042 26.9693L31.5008 34.3147C32.0974 35.73 31.4337 37.3609 30.0185 37.9575C28.6032 38.5541 26.9723 37.8905 26.3756 36.4752L26.1304 35.8936H25.1148L24.8696 36.4752C24.273 37.8905 22.6421 38.5541 21.2268 37.9575C19.8115 37.3609 19.1479 35.73 19.7445 34.3147L22.841 26.9693Z" fill="#09203E"/>
<path d="M33.1104 28.0143C33.1104 26.4784 34.3554 25.2334 35.8913 25.2334C36.0127 25.2334 36.1322 25.2411 36.2495 25.2562C37.2055 25.1533 38.1819 25.5863 38.7301 26.454L39.2393 27.2601C39.568 26.0907 40.6423 25.2334 41.9167 25.2334C43.4526 25.2334 44.6977 26.4784 44.6977 28.0143V35.4302C44.6977 36.9661 43.4526 38.2111 41.9167 38.2111C41.7605 38.2111 41.6074 38.1983 41.4582 38.1735C40.5799 38.1883 39.7153 37.7581 39.2119 36.9612L38.6116 36.0109C38.3445 37.268 37.2281 38.2111 35.8913 38.2111C34.3554 38.2111 33.1104 36.9661 33.1104 35.4302V28.0143Z" fill="#09203E"/>
<path d="M22.5847 40.0651C23.6308 40.0651 24.5419 40.6426 25.0167 41.4963L25.6339 40.9693C27.0355 39.7725 29.1419 39.9385 30.3387 41.3401C31.1366 42.2746 31.0259 43.6788 30.0915 44.4767L27.6287 46.5796L30.7917 49.2803C31.5704 49.9452 31.6626 51.1155 30.9978 51.8941L30.6968 52.2466C29.5332 53.6093 27.4853 53.7707 26.1226 52.6072L25.1847 51.8064C24.786 52.854 23.7723 53.5985 22.5847 53.5985C21.0488 53.5985 19.8038 52.3534 19.8038 50.8175V42.8461C19.8038 41.3102 21.0488 40.0651 22.5847 40.0651Z" fill="#09203E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.1896 46.1381C43.9226 45.7347 44.4195 44.9532 44.4195 44.0553C44.4195 42.6604 43.4842 41.8173 42.9772 41.4599C42.3988 41.0522 41.7279 40.7951 41.1242 40.6336C39.9186 40.3111 38.3818 40.2139 36.9819 40.565C35.6061 40.91 34.5262 41.8176 33.9549 42.9361C33.4097 44.0035 33.1787 45.695 34.3547 47.0432L34.4066 47.1027L34.4623 47.1585C34.6639 47.3607 34.8761 47.5351 35.0919 47.6863C34.638 47.799 34.2127 48.0474 33.8814 48.4272C33.0206 49.414 33.1203 50.9139 34.104 51.7773C35.009 52.5717 36.1612 52.9763 37.1614 53.1657C38.1944 53.3612 39.316 53.3756 40.3475 53.1869C41.3136 53.0101 42.5514 52.5886 43.4258 51.5974C43.896 51.0644 44.2445 50.3788 44.3416 49.5701C44.4368 48.7767 44.2715 48.0249 43.9789 47.3645C43.7649 46.8816 43.4938 46.4775 43.1896 46.1381Z" fill="#09203E"/>
<g>
<rect x="49.8887" width="64" height="64" rx="32" fill="white"></rect>
<rect x="50.7038" y="0.815087" width="62.3698" height="62.3698" rx="31.1849" fill="#1A0826"></rect>
<rect x="50.7038" y="0.815087" width="62.3698" height="62.3698" rx="31.1849" stroke="white" stroke-width="1.63017"></rect>
<g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.6515 35.6853L59.0764 39.155H73.9485L73.4624 35.1818H66.5273L66.4673 34.6889L72.8538 30.2147L72.4271 26.7375H57.5557L58.0424 30.7114H64.988L65.0492 31.208L58.6515 35.6853Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.1548 26.7373L75.6755 39.1548H90.5593L89.038 26.7373H74.1548Z" fill="#AA8FFF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75.5967 25.0897C76.5708 25.9583 77.9758 25.9583 78.7347 25.0897C79.4941 24.221 79.3197 22.8123 78.3457 21.9436C77.3723 21.0743 75.9672 21.0743 75.2077 21.9436C74.4489 22.8123 74.6227 24.221 75.5967 25.0897Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M100.667 33.19L96.0087 33.195L95.6438 30.2151L100.326 30.2188C101.427 30.2318 101.991 30.8537 102.082 31.7044C102.14 32.2519 101.89 33.19 100.667 33.19ZM106.168 31.2084C105.824 28.417 103.638 26.7255 100.666 26.7372H90.7656L92.2864 39.1554H96.7397L96.4348 36.6715H101.148C104.857 36.6715 106.555 34.3538 106.168 31.2084Z" fill="white"/>
</g>
</g>
</svg>
`;

export const Layout: FC<
	PropsWithChildren<CommonProps & LayoutProps & { style?: CSSProperties }>
> = ({ onClose, children, style, ...props }) => {
	return (
		<AsteriskProvider hasAsterisk={Boolean(props.terms)}>
			<div className={styles.component} style={style}>
				<VisuallyHidden as="h1">
					{props.statusText} Here is your compliment. Please press ESC
					to close this window.
				</VisuallyHidden>
				<div className={styles.wrapper}>
					<Header {...props}>{props.statusText}</Header>
					<div className={styles.decoration}>
						<div className={styles.decorationWrapper}>
							<Presentation
								currentImage={props.currentImage}
								previousImage={props.previousImage}
							/>
						</div>
						{props.badge && <Badge>{props.badge}</Badge>}
					</div>
					<Content theme={props.theme} terms={props.terms}>
						{props.withLogo && (
							<div
								className={styles.partnership}
								dangerouslySetInnerHTML={{ __html: logo }}
							/>
						)}
						{children}
					</Content>
				</div>
				<div className={styles.close}>
					<ContextCloseTeleporter.Target>
						<IconButton
							onClick={onClose}
							icon={<CloseIcon />}
							actionName="close"
							aria-label="Close thanks widget"
						>
							Close thanks ads
						</IconButton>
					</ContextCloseTeleporter.Target>
				</div>
				<PopupTeleporterTarget />
			</div>
		</AsteriskProvider>
	);
};
