import { observe as observeEntry } from './records';

let customHeimdallAction: ((group: string, data: any) => void) | null = null;

export const setCustomHeimdallAction = (
	action: (group: string, data: any) => void,
) => {
	customHeimdallAction = action;
};

export const heimdallClientSees = (group: string, data: any) => {
	customHeimdallAction?.(group, data);

	return observeEntry({ traceId: 'client', group, data });
};
