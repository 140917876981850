/* eslint-disable @typescript-eslint/no-unused-vars */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { heimdallClientSees } from '@theway/heimdall/client';

let ufApiKey: string | null = null;
let ufUserId: string | null = null;
let ufTrackQueue = [];
let ufAnonymousId = '';
let ufAllowCookies = false;
let ufCookieSameSiteSetting = 'Strict';
let ufLocationEnrichmentEnabled = true;
let ufDeviceDataEnrichmentEnabled = true;
let ufDefaultTrackingProperties = {};
let ufCustomQueryParamsToCollect = [];
let ufDisableUserIdStorage = false;
let ufCookieExpiryDays = 365;

export function initialize(apiKey: string, options: object) {
	try {
		ufApiKey = apiKey;

		if ('allowCookies' in options && options['allowCookies'] == true) {
			ufAllowCookies = true;
		}

		if (
			'cookieSameSiteSetting' in options &&
			options['cookieSameSiteSetting'] == 'Lax'
		) {
			ufCookieSameSiteSetting = 'Lax';
		}

		if (
			'cookieExpiryDays' in options &&
			typeof options['cookieExpiryDays'] === 'number'
		) {
			ufCookieExpiryDays = options['cookieExpiryDays'];
		}

		if (
			'disableUserIdStorage' in options &&
			options['disableUserIdStorage'] == true
		) {
			ufDisableUserIdStorage = true;
		}

		ufAnonymousId = getOrCreateAnonymousId();
		ufUserId = getUserId();
		ufTrackQueue = loadEventsFromStorage();

		if ('autoEnrich' in options && options['autoEnrich'] == false) {
			ufLocationEnrichmentEnabled = false;
			ufDeviceDataEnrichmentEnabled = false;
		}

		if (
			'defaultTrackingProperties' in options &&
			typeof options['defaultTrackingProperties'] === 'object'
		) {
			ufDefaultTrackingProperties = options['defaultTrackingProperties'];
		}

		if (
			'customQueryParamsToCollect' in options &&
			Array.isArray(options['customQueryParamsToCollect']) === true
		) {
			ufCustomQueryParamsToCollect =
				options['customQueryParamsToCollect'];
		}

		startFlushInterval();

		if ('autoCapture' in options) {
			setupAutoTracking(options['autoCapture']);
		}

		if (ufDisableUserIdStorage == true && ufUserId != null) {
			getStorage()?.removeItem('uf-userId');
		}
	} catch (error) {
		console.info('Failed to initialize UserFlux SDK: ', error);
	}
}

export function updateDefaultTrackingProperties(properties) {
	if (typeof properties !== 'object') {
		console.info('UF defaultTrackingProperties must be an object.');

		return;
	}

	ufDefaultTrackingProperties = properties;
}

function getStorage() {
	if (typeof window === 'undefined') {
		return null;
	}

	return {
		setItem: (key, value) => {
			try {
				const shouldSkipForLocalStorage =
					ufDisableUserIdStorage == true && key === 'uf-userId';
				if (!shouldSkipForLocalStorage && isLocalStorageAccessible())
					localStorage.setItem(key, value);

				const shouldSkipForCookieStorage = key == 'uf-track';
				if (ufAllowCookies == true && !shouldSkipForCookieStorage)
					setCookie(key, value, ufCookieExpiryDays);
			} catch (error) {
				console.info('Error setting item to storage: ', error);
			}
		},
		getItem: (key) => {
			try {
				return (
					(isLocalStorageAccessible()
						? localStorage.getItem(key)
						: null) ||
					(ufAllowCookies == true ? getCookie(key) : null)
				);
			} catch (error) {
				console.info('Error getting item from storage: ', error);

				return null;
			}
		},
		removeItem: (key) => {
			try {
				if (isLocalStorageAccessible()) localStorage.removeItem(key);
				if (ufAllowCookies == true) eraseCookie(key);
			} catch (error) {
				console.info('Error removing item from storage: ', error);
			}
		},
	};
}

let sessionIdImplementation;

export const setSessionIdImplementation = (imp: any) =>
	(sessionIdImplementation = imp);
export const getSessionId = () => sessionIdImplementation();

function setupAutoTracking(autoCaptureOptions) {
	if (typeof autoCaptureOptions !== 'object') {
		// The typeof operator returns " object " for arrays because in JavaScript arrays are objects.
		console.info('UF autoCapture must be an array.');

		return;
	}

	if (
		autoCaptureOptions.includes('page_views') ||
		autoCaptureOptions.includes('all')
	) {
		setupPageViewListener();
	}

	if (
		autoCaptureOptions.includes('page_leaves') ||
		autoCaptureOptions.includes('all')
	) {
		setupPageLeaveListener();
	}

	if (
		autoCaptureOptions.includes('clicks') ||
		autoCaptureOptions.includes('all')
	) {
		setupClickListener();
	}
}

function setupPageViewListener() {
	// Check if running in a browser environment
	if (typeof window === 'undefined') {
		return;
	}

	window.addEventListener('pageshow', (event) => {
		trackPageView();
	});
}

function setupPageLeaveListener() {
	// Check if running in a browser environment
	if (typeof window === 'undefined') {
		return;
	}

	window.addEventListener('pagehide', (event) => {
		trackPageLeave();
	});
}

async function trackPageView() {
	await track({
		event: 'page_view',
		properties: {
			...getPageProperties(),
			...(getReferrerProperties() || {}),
			...(getUTMProperties() || {}),
			...(getPaidAdProperties() || {}),
		},
		addToQueue: false,
	});
}

function setupClickListener() {
	// Check if running in a browser environment
	if (typeof window === 'undefined') {
		return;
	}

	document.addEventListener('click', (event) => {
		const element = event.target.closest(
			'a, button, input[type="submit"], input[type="button"]',
		);

		// If the clicked element or its parent is not what we want to track, return early.
		if (!element) return;

		trackClick(element);
	});
}

function trackClick(element) {
	const properties = {
		elementTagName: element.tagName,
		elementInnerText:
			element.innerText && element.innerText.length < 200
				? element.innerText.trim()
				: undefined,
		elementId: element.id && element.id !== '' ? element.id : undefined,
		...getPageProperties(),
	};

	// Filter out properties that are undefined
	const filteredProperties = Object.keys(properties).reduce((obj, key) => {
		if (properties[key] !== undefined) {
			obj[key] = properties[key];
		}

		return obj;
	}, {});

	track({
		event: 'click',
		properties: {
			...filteredProperties,
		},
		addToQueue: false,
	});
}

function trackPageLeave() {
	track({
		event: 'page_leave',
		properties: {
			...getPageProperties(),
		},
		addToQueue: false,
	});
}

function isApiKeyProvided() {
	return ufApiKey !== null;
}

function getOrCreateAnonymousId() {
	let anonymousId;

	if (isStringNullOrBlank(ufAnonymousId)) {
		// default value is '' which means it hasn't been set yet
		// fetch from storage, if it isn't there then create a new ID
		anonymousId =
			getStorage()?.getItem('uf-anonymousId') ?? createNewAnonymousId();
	} else {
		// otherwise value is set
		anonymousId = ufAnonymousId;
	}

	// Update anonymousId in memory + local + cookie storage to prevent it from expiring
	ufAnonymousId = anonymousId;
	getStorage()?.setItem('uf-anonymousId', anonymousId);

	return anonymousId;
}

function createNewAnonymousId() {
	return generateUUID();
}

function generateUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
		/[xy]/g,
		function (c) {
			const r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8;

			return v.toString(16);
		},
	);
}

function getUserId() {
	let userId = ufUserId || getStorage()?.getItem('uf-userId');

	// clean up any wrongly stored user ids
	let shouldForceUpdate = false;

	// handle edge case values
	if (isStringNullOrBlank(userId)) {
		userId = null;
		shouldForceUpdate = true;
	}

	if (userId || shouldForceUpdate) {
		// Update userId in local storage to prevent it from expiring
		getStorage()?.setItem('uf-userId', userId);
	}

	return userId;
}

export function getAnonymousId() {
	return getOrCreateAnonymousId();
}

function setUserId(userId) {
	ufUserId = userId;
	getStorage()?.setItem('uf-userId', userId);
}

function loadEventsFromStorage() {
	try {
		const events = getStorage()?.getItem('uf-track');

		return events ? JSON.parse(events) : [];
	} catch (error) {
		console.info('Failed to get tracking events from storage: ', error);
		getStorage()?.removeItem('uf-track');

		return [];
	}
}

async function reset() {
	// Firstly, flush any pending events
	await checkQueue(ufTrackQueue, 'event/ingest/batch', true);

	// Clear all stored data
	ufUserId = null;
	getStorage()?.removeItem('uf-userId');

	ufAnonymousId = null;
	getStorage()?.removeItem('uf-anonymousId');

	ufAnonymousId = createNewAnonymousId();

	getStorage()?.setItem('uf-anonymousId', ufAnonymousId);
}

function startFlushInterval() {
	setInterval(async () => {
		await checkQueue(ufTrackQueue, 'event/ingest/batch', true);
	}, 1500);
}

export async function identify(parameters) {
	// sanity check API key
	if (!isApiKeyProvided()) {
		console.info('API key not provided. Cannot identify user.');

		return;
	}

	// sanity check parameters
	if (!parameters || typeof parameters !== 'object') {
		console.info('Invalid parameters passed to track method');

		return;
	}

	// sanity check userId
	let userId = parameters.userId || ufUserId;
	if (userId && (typeof userId !== 'string' || isStringNullOrBlank(userId)))
		userId = null;
	if (userId !== ufUserId) setUserId(userId);

	// sanity check properties
	const properties = parameters.properties || {};

	if (typeof properties !== 'object') {
		console.info('Invalid properties passed to identify method');

		return;
	}

	// sanity check enrichDeviceData
	const enrichDeviceData =
		parameters.enrichDeviceData || ufDeviceDataEnrichmentEnabled;

	if (typeof enrichDeviceData !== 'boolean') {
		console.info('Invalid enrichDeviceData passed to identify method');

		return;
	}

	// sanity check enrichLocationData
	const enrichLocationData =
		parameters.enrichLocationData || ufLocationEnrichmentEnabled;

	if (typeof enrichLocationData !== 'boolean') {
		console.info('Invalid enrichLocationData passed to identify method');

		return;
	}

	const payload = {
		userId: userId,
		anonymousId: getOrCreateAnonymousId(),
		properties: properties,
		deviceData: enrichDeviceData ? getDeviceProperties() : null,
	};

	return await sendRequest('profile', payload, enrichLocationData);
}

export function track(parameters) {
	// sanity check API key
	if (!isApiKeyProvided()) {
		console.info('API key not provided. Cannot track event.');

		return;
	}

	// sanity check parameters
	if (!parameters || typeof parameters !== 'object') {
		console.info('Invalid parameters passed to track method');

		return;
	}

	// sanity check event
	const event = parameters.event;

	if (!event || typeof event !== 'string' || isStringNullOrBlank(event)) {
		console.info('Invalid event passed to track method');

		return;
	}

	// sanity check userId
	let userId = parameters.userId || ufUserId;
	if (userId && (typeof userId !== 'string' || isStringNullOrBlank(userId)))
		userId = null;
	if (userId !== ufUserId) setUserId(userId);

	// sanity check properties
	const properties = parameters.properties || {};

	if (typeof properties !== 'object') {
		console.info('Invalid properties passed to track method');

		return;
	}

	// sanity check enrichDeviceData
	const enrichDeviceData =
		parameters.enrichDeviceData || ufDeviceDataEnrichmentEnabled;

	if (typeof enrichDeviceData !== 'boolean') {
		console.info('Invalid enrichDeviceData passed to track method');

		return;
	}

	// sanity check enrichLocationData
	const enrichLocationData =
		parameters.enrichLocationData || ufLocationEnrichmentEnabled;

	if (typeof enrichLocationData !== 'boolean') {
		console.info('Invalid enrichLocationData passed to track method');

		return;
	}

	const enrichPageProperties = parameters.enrichPageProperties || true;

	if (typeof enrichPageProperties !== 'boolean') {
		console.info('Invalid enrichPageProperties passed to track method');

		return;
	}

	const enrichReferrerProperties =
		parameters.enrichReferrerProperties || true;

	if (typeof enrichReferrerProperties !== 'boolean') {
		console.info('Invalid enrichReferrerProperties passed to track method');

		return;
	}

	const enrichUTMProperties = parameters.enrichUTMProperties || true;

	if (typeof enrichUTMProperties !== 'boolean') {
		console.info('Invalid enrichUTMProperties passed to track method');

		return;
	}

	const enrichPaidAdProperties = parameters.enrichPaidAdProperties || true;

	if (typeof enrichPaidAdProperties !== 'boolean') {
		console.info('Invalid enrichPaidAdProperties passed to track method');

		return;
	}

	// sanity check addToQueue
	const addToQueue = parameters.addToQueue || false;

	if (typeof addToQueue !== 'boolean') {
		console.info('Invalid addToQueue passed to track method');

		return;
	}

	// combine event properties with any default tracking properties
	const finalProperties = {
		...properties,
		...ufDefaultTrackingProperties,
		...(enrichPageProperties ? getPageProperties() : {}),
		...(enrichReferrerProperties ? getReferrerProperties() : {}),
		...(enrichUTMProperties ? getUTMProperties() : {}),
		...(enrichPaidAdProperties ? getPaidAdProperties() : {}),
		...(getCustomQueryParamProperties() || {}),
	};

	const payload = {
		timestamp: Date.now(),
		userId: userId,
		anonymousId: getOrCreateAnonymousId(),
		sessionId: getSessionId(),
		name: event,
		properties: finalProperties,
		deviceData: enrichDeviceData ? getDeviceProperties() : null,
	};

	heimdallClientSees('userflux:client', payload);

	if (addToQueue) {
		const shouldForceFlush = getStorage() == null;
		ufTrackQueue.push(payload);
		saveEventsToStorage('uf-track', ufTrackQueue);

		return checkQueue(ufTrackQueue, 'event/ingest/batch', shouldForceFlush);
	} else {
		return sendRequest(
			'event/ingest/batch',
			{ events: [payload] },
			enrichLocationData,
		);
	}
}

async function trackBatch(events) {
	for (const event of events) {
		await track({ ...event, addToQueue: true });
	}

	await flush();

	return;
}

export async function flush() {
	await checkQueue(ufTrackQueue, 'event/ingest/batch', true);
}

function saveEventsToStorage(key, queue) {
	getStorage()?.setItem(key, JSON.stringify(queue));
}

async function checkQueue(queue, eventType, forceFlush) {
	if (queue.length >= 10 || (forceFlush && queue.length > 0)) {
		await flushEvents(queue, eventType);
	}
}

async function flushEvents(queue, eventType) {
	if (!isApiKeyProvided()) {
		console.info('API key not provided. Cannot flush events.');

		return;
	}

	await sendRequest(eventType, { events: queue.splice(0, 10) });
	saveEventsToStorage(`uf-track`, queue);

	// If the queue is not empty, check it again
	if (queue.length > 0) {
		await checkQueue(queue, eventType, true);
	}
}

async function sendRequest(
	endpoint,
	data,
	locationEnrich = ufLocationEnrichmentEnabled,
) {
	if (!isApiKeyProvided()) {
		console.info('API key not provided. Cannot send request.');

		return;
	}

	try {
		return await fetch(
			`https://integration-api.userflux.co/${endpoint}?locationEnrichment=${locationEnrich}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${ufApiKey}`,
				},
				body: JSON.stringify(data),
				keepalive: true,
			},
		);
	} catch (error) {
		console.info('UF Error: ', error);
	}
}

function getPageProperties() {
	try {
		// Check if running in a browser environment
		if (typeof window === 'undefined') {
			return {};
		}

		return removeNullProperties({
			host: window.location.host,
			href: window.location.href,
			path: window.location.pathname,
			pageTitle: document.title,
		});
	} catch (e) {
		console.info('Error on getPageProperties:', error);

		return {};
	}
}

function getDeviceProperties() {
	try {
		// Check if running in a browser environment
		if (typeof window === 'undefined') {
			return null;
		}

		const userAgent = window.navigator.userAgent;
		let browser, browserVersion, deviceType, os;

		// Determine Browser and Browser Version
		if (userAgent.indexOf('Chrome') > -1) {
			browser = 'Chrome';

			const match = userAgent.match(/Chrome\/(\d+)/);
			browserVersion = match ? match[1] : 'Unknown';
		} else if (userAgent.indexOf('CriOS') > -1) {
			browser = 'Chrome';

			const match = userAgent.match(/CriOS\/([\d.]+)/);
			browserVersion = match ? match[1] : 'Unknown';
		} else if (userAgent.indexOf('Safari') > -1) {
			browser = 'Safari';

			const match = userAgent.match(/Version\/([\d.]+)/);
			browserVersion = match ? match[1] : 'Unknown';
		} else if (userAgent.indexOf('Firefox') > -1) {
			browser = 'Firefox';

			const match = userAgent.match(/Firefox\/([\d.]+)/);
			browserVersion = match ? match[1] : 'Unknown';
		} else if (
			userAgent.indexOf('MSIE') > -1 ||
			userAgent.indexOf('Trident') > -1
		) {
			browser = 'Internet Explorer';

			const match = userAgent.match(/(?:MSIE |rv:)(\d+)/);
			browserVersion = match ? match[1] : 'Unknown';
		} else {
			browser = 'Unknown';
			browserVersion = 'Unknown';
		}

		// Determine Device Type
		if (/Mobi|Android/i.test(userAgent)) {
			deviceType = 'Mobile';
		} else {
			deviceType = 'Desktop';
		}

		// Determine OS
		if (/iPhone|iPad|iPod/i.test(userAgent)) {
			os = 'iOS';
		} else if (userAgent.indexOf('Mac OS X') > -1) {
			os = 'Mac OS X';
		} else if (userAgent.indexOf('Windows NT') > -1) {
			os = 'Windows';
		} else if (userAgent.indexOf('Android') > -1) {
			os = 'Android';
		} else if (userAgent.indexOf('Linux') > -1) {
			os = 'Linux';
		} else {
			os = 'Unknown';
		}

		return removeNullProperties({
			userAgent: userAgent,
			browser: browser,
			browserVersion: browserVersion,
			deviceType: deviceType,
			os: os,
			screenWidth: window.screen.width,
			screenHeight: window.screen.height,
			browserWidth: window.innerWidth,
			browserHeight: window.innerHeight,
		});
	} catch (error) {
		console.info('Error:', error);

		return null;
	}
}

function getCustomQueryParamProperties() {
	try {
		// Check if there are any custom query parameters to collect
		if (ufCustomQueryParamsToCollect.length == 0) return null;

		// Check if running in a browser environment
		if (typeof window === 'undefined') {
			return null;
		}

		// Pickup any custom query parameters from the href, default to null if it doesn't exist
		const locationHref = window.location.href;
		const urlSearchParams = new URLSearchParams(
			new URL(locationHref).search,
		);

		const customQueryParams = {};

		ufCustomQueryParamsToCollect.forEach((param) => {
			customQueryParams[param] = urlSearchParams.get(param) || null;
		});

		// Remove any null properties from the object before returning
		return removeNullProperties(customQueryParams);
	} catch (error) {
		console.info('Error for getCustomQueryParamProperties(): ', error);

		return null;
	}
}

function getUTMProperties() {
	try {
		// Check if running in a browser environment
		if (typeof window === 'undefined') {
			return null;
		}

		const locationHref = window.location.href;

		// Extract query parameters
		const urlSearchParams = new URLSearchParams(
			new URL(locationHref).search,
		);
		const queryParams = {
			utmSource: urlSearchParams.get('utm_source') || null,
			utmMedium: urlSearchParams.get('utm_medium') || null,
			utmCampaign: urlSearchParams.get('utm_campaign') || null,
			utmTerm: urlSearchParams.get('utm_term') || null,
			utmContent: urlSearchParams.get('utm_content') || null,
			utmId: urlSearchParams.get('utm_id') || null,
			utmSourcePlatform:
				urlSearchParams.get('utm_source_platform') || null,
		};

		return removeNullProperties(queryParams);
	} catch (error) {
		console.info('Error for getUTMProperties(): ', error);

		return null;
	}
}

function getPaidAdProperties() {
	try {
		// Check if running in a browser environment
		if (typeof window === 'undefined') {
			return null;
		}

		const locationHref = window.location.href;

		// Extract query parameters
		const urlSearchParams = new URLSearchParams(
			new URL(locationHref).search,
		);
		const queryParams = {
			gclid: urlSearchParams.get('gclid') || null,
			fbclid: urlSearchParams.get('fbclid') || null,
			msclkid: urlSearchParams.get('msclkid') || null,
		};

		return removeNullProperties(queryParams);
	} catch (error) {
		console.info('Error for getPaidAdProperties(): ', error);

		return null;
	}
}

function getReferrerProperties() {
	try {
		// Check if running in a browser environment
		if (typeof window === 'undefined') {
			return null;
		}

		return removeNullProperties({
			referrerHref: document.referrer !== '' ? document.referrer : null,
			referrerHost: document.referrer
				? new URL(document.referrer).hostname
				: null,
		});
	} catch (error) {
		console.info('Error getReferrerProperties(): ', error);

		return null;
	}
}

// Utility function to set a cookie
function setCookie(name, value, days) {
	try {
		let expires = '';

		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}

		// Set SameSite setting
		const sameSite = `; SameSite=${ufCookieSameSiteSetting}`;

		// Dynamically determine the base domain
		const hostMatchRegex =
			/^(?:https?:\/\/)?(?:[^\/]+\.)?([^.\/]+\.(?:co\.uk|com\.au|com|co|money|io|is)).*$/i;
		const matches = document.location.hostname.match(hostMatchRegex);
		const domain = matches ? matches[1] : '';
		const cookieDomain = domain ? '; domain=.' + domain : '';

		document.cookie =
			name +
			'=' +
			(value || '') +
			expires +
			sameSite +
			'; Secure' +
			cookieDomain +
			'; path=/';
	} catch (error) {
		console.info('Error:', error);
	}
}

// Utility function to get a cookie
function getCookie(name) {
	try {
		const nameEQ = name + '=';
		const ca = document.cookie.split(';');

		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0)
				return c.substring(nameEQ.length, c.length);
		}

		return null;
	} catch (error) {
		console.info('Error:', error);

		return null;
	}
}

// Utility function to erase a cookie
function eraseCookie(name) {
	try {
		// Dynamically determine the base domain
		const hostMatchRegex = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
		const matches = document.location.hostname.match(hostMatchRegex);
		const domain = matches ? '; domain=.' + matches[0] : '';

		document.cookie =
			name + '=; Max-Age=-99999999; path=/' + '; domain=.' + domain;
	} catch (error) {
		console.info('Error:', error);
	}
}

// Method to check if localStorage is accessible
function isLocalStorageAccessible() {
	try {
		// Try to use localStorage
		localStorage.setItem('uf-ls-test', 'test');
		localStorage.removeItem('uf-ls-test');

		return true;
	} catch (e) {
		// Catch any errors, including security-related ones
		return false;
	}
}

// Method to check if a strings value is null or empty
// Handles edges cases where values retrieve from storage come back as string values instead of null
function isStringNullOrBlank(value) {
	if (typeof value !== 'string') return true;

	return (
		!value ||
		value == null ||
		value == undefined ||
		value == '' ||
		value == 'null' ||
		value == 'undefined'
	);
}

// Method to remove null properties from an object
// Used for cleaning up the properties object of a event before tracking
function removeNullProperties(object) {
	return Object.fromEntries(
		Object.entries(object).filter(([key, value]) => value !== null),
	);
}
