import type { AnalyticsEvent, AnalyticsAdapter } from '@theway/analytics';

import * as UserFlux from './user-flux-frontend';

export const getAnonymousId = () => UserFlux.getAnonymousId();

const createBlockedQueue = () => {
	let queue: any[] = [];
	let unlocked = false;

	return {
		push(fn: () => any) {
			if (unlocked) {
				fn();
			} else {
				queue.push(fn);
			}
		},
		unlock() {
			queue.forEach((fn) => fn());
			queue = [];
			unlocked = true;
		},
	};
};

export const initUserFlux = (id: string): AnalyticsAdapter => {
	const track = (event: string, properties: any) => {
		q.push(() =>
			UserFlux.track({
				event,
				properties,
				addToQueue: true,
				enrichDeviceData: true,
				enrichLocationData: true,
				enrichPageProperties: true,
				enrichReferrerProperties: true,
				enrichUTMProperties: true,
			}),
		);
	};

	const q = createBlockedQueue();

	UserFlux.initialize(id, {
		autoCapture: [],
		allowCookies: true,
		autoEnrich: true,
		trackSession: false,
	});

	return {
		identify(impression, referral, options) {
			const { attributes = {}, ...restProps } = options;
			UserFlux.setSessionIdImplementation(() => impression);

			UserFlux.identify({
				userId: options.hash,
				properties: {
					referral,
					...attributes,
					...restProps,
				},
				enrichDeviceData: true,
				enrichLocationData: true,
			});

			UserFlux.updateDefaultTrackingProperties({
				publisher: options.publisher,
				referral: options.referral,
				impression,
				theme: options.theme,
			});

			// DEBUG:report extra attributes
			track('device-report', {
				serverDeviceMode:
					options.impressionRecord?.deviceMode || 'unknown',
				clientDeviceMode: options.displayMode,
				windowWidth: window.innerWidth,
			});

			q.unlock();
		},
		async flush() {
			await UserFlux.flush();
		},
		heartBeat(position, type, experienceId) {
			track('heartbeat', {
				position,
				type,
				experienceId,
			});
		},
		phaseMetric(phase, attributes = {}) {
			track('phase', {
				phase,
				delta: performance.now(),
				...attributes,
			});
		},
		uiEvent: (event: AnalyticsEvent) => {
			switch (event.action) {
				case 'view': {
					track('view', event);

					return;
				}

				case 'click': {
					track('click', event);

					return;
				}

				default: {
					track(event.action, event);
				}
			}
		},
		embed: {
			view(attributes) {
				track('embed-view', {
					...attributes,
				});
			},
			action(attributes) {
				track('embed-action', {
					...attributes,
				});
			},
		},
		ad: {
			exposure(adId, attributes) {
				track('ad-exposure', {
					adId,
					...attributes,
				});
			},
			view(adId, attributes) {
				track('ad-view', {
					adId,
					...attributes,
				});
			},
			action(adId, type, attributes) {
				track(
					'ad-action' + (type ? `-${type.replaceAll(' ', '-')}` : ''),
					{
						adId,
						...attributes,
					},
				);
			},
			skip(adId, attributes) {
				track('ad-skip', {
					adId,
					...attributes,
				});
			},
		},
		charity: {
			choice(charityName) {
				track('charity-choice', {
					charityName,
				});
			},
		},
	};
};
