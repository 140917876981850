export enum Media {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg',
	XL = 'xl',
	XXL = 'xxl',
}

export const BREAKPOINTS: Record<Media, number> = {
	[Media.XS]: 360,
	[Media.SM]: 768,
	[Media.MD]: 1024,
	[Media.LG]: 1200,
	[Media.XL]: 1350,
	[Media.XXL]: 1680,
};

const useContainerQuery = false;

export const from = (bp: Media) =>
	useContainerQuery
		? `@container (min-width: ${BREAKPOINTS[bp]}px)`
		: `@media (min-width: ${BREAKPOINTS[bp]}px)`;

export const until = (bp: Media) =>
	`@media (max-width: ${BREAKPOINTS[bp] - 1}px)`;

export const between = (from: Media, to: Media) =>
	`@media (min-width: ${BREAKPOINTS[from]}px) and (max-width: ${
		BREAKPOINTS[to] - 1
	}px)`;

export const container = (name: string, condition: string) =>
	`@container ${name} (${condition})`;
