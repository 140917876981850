import { Logo } from '@smile/ds/logo';
import { TextSm } from '@smile/ds/typography';
import type { FC, PropsWithChildren } from 'react';

import { Pagination } from '../pagination';
import type { PaginationControlProps } from '../pagination';

import * as styles from './styles';

export const HeaderTitle: FC<
	PropsWithChildren & { iconTheme: 'dark' | 'light' }
> = ({ children, iconTheme }) => {
	return (
		<div className={styles.title}>
			<Logo theme={iconTheme} width={24} />
			<TextSm as="p">{children}</TextSm>
		</div>
	);
};

export const Header: FC<PropsWithChildren & PaginationControlProps> = ({
	children,
	...props
}) => {
	return (
		<div className={styles.header}>
			<HeaderTitle iconTheme={'light'}>{children}</HeaderTitle>
			<Pagination {...props} />
		</div>
	);
};
