import { setCustomHeimdallAction } from '@theway/heimdall/client';

import { addAnalyticsFinalization } from './finalizers';

export const configureHeimdallClient = () => {
	let previousQueue: Promise<any> = Promise.resolve();

	const pushQueue = (cb: () => any) => {
		previousQueue = previousQueue.then(cb);
	};

	addAnalyticsFinalization(
		() => new Promise<void>((resolve) => pushQueue(() => resolve())),
	);

	setCustomHeimdallAction((group, data) => {
		// @ts-expect-error untyped access
		const { slice, hmdl } = window.__env__;

		if (hmdl) {
			pushQueue(() =>
				fetch(`/api/heimdall/sees/${group}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ ...data, slice }),
				}),
			);
		}
	});
};
