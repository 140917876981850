import useFlux, { activateUFLinkTracker } from './bridge-user-flux';
import { getConsoleAnalytics } from './console-stub';

import { addAnalyticsAdapter } from './index';

export const initWidgetAnalytics = () => {
	if (import.meta.env.DEV) {
		addAnalyticsAdapter(getConsoleAnalytics());
	}

	addAnalyticsAdapter(useFlux);
};

export const activateLinkTracker = () => activateUFLinkTracker();
