import { createTouchSignal } from '@smile/touch-signal';
import { type FC, type ReactNode } from 'react';

const trackers = {};

const signal = createTouchSignal();

const applyTrackers = (url: string, trackers: Record<string, string>) => {
	const [base, search] = url.split('?');
	const params = new URLSearchParams(search);

	Object.entries(trackers).forEach(([key, value]) => {
		params.set(key, value);
	});

	const newSearch = params.toString();

	return newSearch ? `${base}?${newSearch}` : base;
};

export const applyLinkTrackers = (
	url: string,
	extra: Record<string, number | string> = {},
) => {
	return applyTrackers(url, { ...trackers, ...extra });
};

export const useLinkTrackers = (
	url: string,
	extra: Record<string, number | string> = {},
) => {
	signal.useTouch();

	return applyLinkTrackers(url, extra);
};

export const currentLinkTrackers = () => {
	return trackers;
};

export const WithLinkTracker: FC<{
	url: string;
	children: (url: string) => ReactNode;
}> = ({ url, children }) => {
	return children(useLinkTrackers(url));
};

export const setLinkTracker = (tracker: Record<string, string>) => {
	Object.assign(trackers, tracker);
	signal.touch();
};
