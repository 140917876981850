/**
 * mind - files of this folder should be listed as sideEffects
 */
import './configure';
import '@theway/helpers-css/reset';
import '@smile/font-assets';

import { lazyInitWidgetAnalytics } from '../analytics/init';
import { initSentry } from '../sentry';

// ----
lazyInitWidgetAnalytics();
initSentry();
